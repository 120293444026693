@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.color-blue-50 {
  background-color: #e3f2fd;
}

.color-red-50 {
  background-color: #ffebee;
}

.color-green-50 {
  background-color: #e8f5e9;
}

.color-yellow-50 {
  background-color: #fffde7;
}

.color-amber-50 {
  background-color: #fff8e1;
}

.color-orange-50 {
  background-color: #fbe9e7;
}

.negative-period {
  color: red;
}

.app-page-title {
  position: relative;
  margin-bottom: 50px;
}

.page-title-heading {
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  align-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.page-title-icon {
  font-size: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0.83333rem;
  margin: 0 30px 0 0;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-radius: 0.25rem;
  width: 60px;
  height: 60px;
}

.page-title-subheading {
  padding: 3px 0 0;
  font-size: 0.88rem;
  opacity: 0.6;
}

.page-title-action {
  margin-left: auto;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  margin-top: 50px;
}

.no-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(90vh - 98px);
  color: lightgray;
}
